import { nanoid } from 'nanoid';
import { getUserMock } from 'stores/user';
import { StudySet, StudySetCard } from './studySet.types';

export const getCardMock = (overrides?: Partial<StudySetCard>): StudySetCard => ({
  id: nanoid(),
  terms: ['cat'],
  relatedTerms: ['кот'],
  hasDefinitions: false,
  definitions: [],
  hasExamples: false,
  examples: [],
  learningLevel: 5,
  ...overrides,
});

const generateCardMocks = (count: number): StudySetCard[] => {
  const mockCards = [];

  for (let i = 0; i < count; i++) {
    mockCards.push(
      getCardMock({
        terms: [`term${i}`],
        relatedTerms: [`relatedTerm${i}`],
        learningLevel: Math.floor(Math.random() * 11),
      })
    );
  }

  return mockCards;
};

export const getStudySetMock = (overrides?: Partial<StudySet>): StudySet => ({
  id: nanoid(),
  name: 'Study Set',
  description: 'description',
  progress: 70,
  level: 'Hard',
  totalCards: 45,
  numLearnedCards: 25,
  isPublic: true,
  isActive: false,
  owner: {
    ...getUserMock(),
  },
  cards: [
    getCardMock(),
    getCardMock({ terms: ['dog'], relatedTerms: ['пес'], learningLevel: 2 }),
    getCardMock({ terms: ['day'], relatedTerms: ['день'], learningLevel: 8 }),
    getCardMock({
      terms: ['hi', 'hello', 'howdy', 'heeeeey'],
      relatedTerms: ['привет'],
      hasExamples: true,
      examples: ['hello how are you?', 'hello how are you 113123?'],
      learningLevel: 10,
    }),
    getCardMock({
      terms: ['rain'],
      relatedTerms: ['дождь', 'дождик'],
      hasDefinitions: true,
      definitions: ['drops of water from clouds'],
      learningLevel: 1,
    }),
    ...generateCardMocks(45),
  ],
  ...overrides,
});
