// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);
    box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

body {
  scrollbar-color: #363563 #272747;
}

.pulse {
  animation: pulse 2s infinite;
}

.active-point {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #4affd7;
  border-radius: 20px;
}

.stub {
  width: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAcA;EACE;IACE,2CAA2C;IAC3C,2CAA2C;EAC7C;;EAEA;IACE,6CAA6C;IAC7C,6CAA6C;EAC/C;;EAEA;IAEE,0CAA0C;EAC5C;AACF;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb","sourcesContent":["@-webkit-keyframes pulse {\n  0% {\n    -webkit-box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);\n  }\n\n  70% {\n    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);\n  }\n\n  100% {\n    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);\n  }\n}\n\n@keyframes pulse {\n  0% {\n    box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);\n    box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);\n  }\n\n  70% {\n    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);\n    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);\n  }\n\n  100% {\n    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);\n    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);\n  }\n}\n\nbody {\n  scrollbar-color: #363563 #272747;\n}\n\n.pulse {\n  animation: pulse 2s infinite;\n}\n\n.active-point {\n  width: 10px;\n  height: 10px;\n  position: absolute;\n  top: 12px;\n  right: 12px;\n  background-color: #4affd7;\n  border-radius: 20px;\n}\n\n.stub {\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
